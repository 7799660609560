import React, { useEffect, useState } from "react"
import BlogLayout from "../../../components/Blog/BlogLayout"
import Template from "../../../components/Blog/Template"
const BlogContent = (req, res) => {
  const [data, setData] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/blog/blogs.json")
        const result = await response.json()
        const slug = req.params.slug

        const filteredData = result.filter(item => item.slug === slug)

        setData(filteredData)
      } catch (error) {
        console.error("Error loading JSON:", error)
      }
    }

    fetchData()
  }, [])

  return (
    <BlogLayout>
      {data?.length > 0 ? (
        <Template blogData={data[0]} />
      ) : (
        <p
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1.5rem",
          }}
        >
          Loading...
        </p>
      )}
    </BlogLayout>
  )
}

export default BlogContent
