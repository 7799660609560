import React from "react"
import Detail from "../BlogDetails/Detail"
import "./Content.scss"
import "../Blog.scss"
import VisibilitySensor from "react-visibility-sensor"
import { Link, Element } from "react-scroll"
import TalkToUs from "../../shared/TalkToUs"
import Head from "../../utils/Head"

function Template({ blogData }) {
  const metaData = blogData.metaData
  const TalkToUsWithoutImg = blogData?.talkToUs
  const tableOfContent = blogData.tableOfContentHeadings

  const checkVisibilityOff = visibility => {
    const el = document.getElementById("table-of-content")
    if (visibility) {
      el.style.display = "none"
    }
  }
  const checkVisibilityOn = visibility => {
    const el = document.getElementById("table-of-content")
    if (visibility) {
      el.style.display = "block"
      el.style.position = "fixed"
    }
  }

  return (
    <>
      <Head title={metaData.title} desc={metaData.desc} />
      <Detail layoutData={blogData.layoutData}>
        <div className="blog-content container">
          <div className="row">
            <div className="col-md-9">
              <div className="talk-class">
                {TalkToUsWithoutImg && (
                  <TalkToUs
                    btnText={TalkToUsWithoutImg.btnText}
                    header={TalkToUsWithoutImg.header}
                    text={TalkToUsWithoutImg.text}
                  />
                )}
              </div>

              {blogData.sections.map((section, index) => (
                <Element key={index} name={section.heading} className="section">
                  {index === 0 ? (
                    <VisibilitySensor
                      onChange={checkVisibilityOn}
                      delayedCall
                      scrollCheck
                      intervalDelay={10}
                    >
                      <h2 className="header">{section.heading}</h2>
                    </VisibilitySensor>
                  ) : (
                    <h2 className="header">{section.heading}</h2>
                  )}

                  <div
                    className="text"
                    dangerouslySetInnerHTML={{ __html: section.desc }}
                  />

                  {section.content &&
                    section.content.map((subsection, subIndex) => (
                      <div key={subIndex}>
                        <h3 className="sub-header">{subsection.subheading}</h3>
                        <div
                          className="text"
                          dangerouslySetInnerHTML={{ __html: subsection.desc }}
                        />
                      </div>
                    ))}
                  {section.talkToUs && (
                    <TalkToUs
                      data={section.talkToUs}
                      btnText={section.talkToUs.btnText}
                      header={section.talkToUs.header}
                      text={section.talkToUs.text}
                      image={section.talkToUs.image}
                      ctaLink={section.talkToUs.ctaLink}
                    />
                  )}
                  {index === blogData.sections.length - 2 && (
                    <VisibilitySensor
                      onChange={checkVisibilityOn}
                      delayedCall
                      scrollCheck
                      intervalDelay={10}
                    >
                      <h2 className="text"></h2>
                    </VisibilitySensor>
                  )}
                  {index === blogData.sections.length - 1 && (
                    <VisibilitySensor
                      onChange={checkVisibilityOff}
                      delayedCall
                      scrollCheck
                      intervalDelay={10}
                    >
                      <h2 className="text"></h2>
                    </VisibilitySensor>
                  )}
                </Element>
              ))}
            </div>
            <div className="col-md-3">
              <div id="table-of-content" className="table-of-content">
                <p className="header-table">Table of Content</p>
                <ul>
                  {tableOfContent.map((heading, index) => (
                    <Link
                      key={index}
                      activeClass="active"
                      to={heading}
                      smooth
                      duration={80}
                      offset={-90}
                      isDynamic
                      delay={0}
                      spy
                    >
                      <li>{heading}</li>
                    </Link>
                  ))}
                </ul>

                <p className="share">Share</p>
                <div className="link-icons d-flex justify-content-between">
                  <a
                    href="https://www.linkedin.com/"
                    target="_blank"
                    className="social-icon"
                    rel="noreferrer"
                  >
                    <img
                      src="/homepage/linkedin.svg"
                      alt="linkedin"
                      className="img-fluid"
                      loading="lazy"
                    />
                  </a>

                  <a
                    href="https://instagram.com/"
                    target="_blank"
                    className="social-icon"
                    rel="noreferrer"
                  >
                    <img
                      src="/homepage/instagram.svg"
                      alt="instagram"
                      className="img-fluid"
                      loading="lazy"
                      id="insta"
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/"
                    target="_blank"
                    className="social-icon"
                    rel="noreferrer"
                  >
                    <img
                      src="/homepage/facebook.svg"
                      alt="facebook"
                      className="img-fluid"
                      loading="lazy"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Detail>
    </>
  )
}

export default Template
