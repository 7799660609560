import React from "react"
import Heading from "../Heading"
import "./Talk.scss"
import Button from "../Button"
function TalkToUs({ header, text, btnText, image = "", ctaLink = "/contact" }) {
  return (
    <div className="talk-to-us">
      <div className="">
        <div className="talk-inner-wrapper">
          {image && (
            <div className="talk-to-us-image">
              <img
                loading="lazy"
                src={`/blog/Blog Detail Optimized/${image}`}
                alt="talk-to-us-image"
              />
            </div>
          )}
          <div className="">
            <Heading variant="40" className="header">
              {header}
            </Heading>
          </div>

          <p className="text">{text}</p>
          <div className="btn-box">
            <Button
              href={ctaLink}
              clr="white"
              text={btnText}
              className="talk-to-us-btn"
              variant="fill"
              // className="nav-btn"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TalkToUs
